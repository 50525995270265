import React from "react"
import { useRef } from "react"
import { useState } from "react"
import axios from "axios"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import "../../assets/css/style.css"
import "../../assets/css/custom.css"
import "../../assets/css/vendor/vendor.min.css"
import "../../assets/css/plugins/plugins.min.css"
import { resetWarningCache } from "prop-types"
import ReCAPTCHA from "react-google-recaptcha"

const ContactUs = () => {

  const [captchaState, setCaptchaState] = useState(false)
  const firstRef = useRef(null)
  const emailRef = useRef(null)
  const contactRef = useRef(null)
  const subjectRef = useRef(null)
  const messageRef = useRef(null)

  const [values, setValues] = useState({
    name: "",
    email: "",
    phone_number: "",
    subject: "",
    message: "",
    from: "",
  })
  const [sent, setSent] = useState(false)
  const handleChange = e => {
    const { name, value } = e.target
    setValues({ ...values, [name]: value })
  }
  async function handleSubmit(e) {

    e.preventDefault()
    if (captchaState === false) {
      return alert("Please complete reCAPTCHA verification.")
    }
    // alert("Thanks for contacting us!.We'll respond to your message shortly.")
    const response = await axios
      .post(
        "https://4zsdh4i36g.execute-api.us-east-1.amazonaws.com/dev/sent-aamanto-email",
        {
          name: `${values.name}`,
          email: `${values.email}`,
          phone_number: `${values.phone_number}`,
          subject: `${values.subject}`,
          message: `${values.message}`,
          from: "Aamanto",
        }
      )

      .then(res => {
        toast(res.data.message)
        //  e.target.reset()
        resetWarningCache({
          name: "",
          email: "",
          phone_number: "",
          subject: "",
          message: "",
        })
      })
      .catch(console.log)
    // console.log(response)
    // setSent(true)
  }


  const onChangeHandlerRecaptcha = (value) => {
    console.log("Captcha value:", value)
    setCaptchaState(true)
  }

  return (
    <div id="main-wrapper">
      {/* <div className="breadcrumb-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumb_box text-center">
                <h1 className="breadcrumb-title">Contact Us</h1>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="contact-banner-wrap banner-space contact-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 m-auto">
              <div className="contact-banner-content text-center">
                <h1 className="mb-15 text-white">Contact Us</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="site-wrapper-reveal">
        {/* <!--====================  Conact us Section Start ====================--> */}
        <div className="contact-us-section-wrappaer">
          <div className="section-space--pb_30 section-space--pt_100 section-space--pt_30 section-title-wrap text-center">
            <h1 className="heading">
              To make requests for further information, <br />
              <span className="text-color-primary">contact us</span> via our
              social channels.{" "}
            </h1>
          </div>
        </div>
        <div className="col-lg-12 col-lg-12 ">
          <div className="contact-form-wrap section-space--pb_40">
            <div className="main-form-styling">
              <form
                onSubmit={handleSubmit}
                id="contact-form"
                to="kamal.shah@aamanto.com"
                name="ContactForm"
                method="POST"
                action="https://4zsdh4i36g.execute-api.us-east-1.amazonaws.com/dev/sent-aamanto-email"
                className="form-inline"
              >
                <div className="contact-form">
                  <div className="contact-inner">
                    <input
                      name="name"
                      type="text"
                      maxLength={30}
                      placeholder="Name *"
                      required
                      onChange={handleChange}
                      value={values.name}
                    />
                  </div>
                  <div className="contact-inner">
                    <input
                      name="email"
                      type="email"
                      maxLength={50}
                      placeholder="Email *"
                      required
                      onChange={handleChange}
                      value={values.email}
                    />
                  </div>
                  <div className="contact-inner">
                    <input
                      name="phone_number"
                      type="number"
                      maxLength={30}
                      placeholder="Contact No *"
                      required
                      onChange={handleChange}
                      value={values.phone_number}
                    />
                  </div>
                  <div className="contact-inner">
                    <input
                      name="subject"
                      type="text"
                      maxLength={50}
                      placeholder="Subject *"
                      required
                      onChange={handleChange}
                      value={values.subject}
                    />
                  </div>

                  <div className="contact-inner contact-message">
                    <textarea
                      style={{ resize: "none" }}
                      maxLength={"500"}
                      name="message"
                      onChange={handleChange}
                      value={values.message}
                      placeholder="Please describe what you need."
                    ></textarea>
                    <span>Max character length:500</span>
                  </div>

                  <ReCAPTCHA
                    sitekey="6LcFsMkiAAAAAE69-g8FFGSrwQ5Utvodfj-lbZyO" //live
                    //sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" //local
                    onChange={onChangeHandlerRecaptcha}
                  />

                  <div className="submit-btn">
                    <button
                      className="ht-btn btn-secondary me-2 ht-btn-md"
                      type="reset"
                      value="Reset"
                    >
                      Reset
                    </button>
                    <button className="ht-btn ht-btn-md" type="submit">Send message</button>
                    <ToastContainer />
                    <p className="form-messege"></p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ContactUs
