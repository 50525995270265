import * as React from "react"
import Layout from "../components/layout"
import ContactUs from "../components/Index/ContactUs"

const Contact = () => {
  return(
  <Layout>
   <ContactUs />
  </Layout>
)
  };
export default Contact;
